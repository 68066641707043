/* ===== Global Theme Styles ===== */


/*

Primary Color (--primary-color)
  The main brand or theme color of the site.
  Used for buttons, links, highlights, and key interactive elements.

Secondary Color (--secondary-color)
  A complementary color to the primary color.
  Used for emphasis, secondary buttons, hover effects, and sometimes backgrounds.

Accent Color (--accent-color)
   Used to draw attention to specific elements, like badges, notifications, or important links.
  Often applied sparingly to create contrast.

Text Color (--text-color)
  Default color for general body text.
  Ensures readability and is usually a shade of black or gray.

Heading Color (--heading-color)
  Used specifically for headings (h1, h2, h3, etc.).
  Typically darker and bolder than body text for emphasis.

Background Color (--background-color)
  The main background of the page or containers.
  Helps create contrast with text and other elements.

Light Color (--light-color)
  Often used for light backgrounds, cards, or text on dark backgrounds.
  Provides a neutral contrast to darker elements.

Dark Color (--dark-color)
  Used for dark backgrounds, footers, or text on light backgrounds.
  Helps create depth and hierarchy.

Muted Color (--muted-color)
  Used for less emphasized elements, like subtitles, secondary text, placeholders, or disabled buttons.
  Usually a soft gray to de-emphasize content.

  
-- First theme used for dev
:root {
  --primary-color: #4CAF50;  
  --secondary-color: #FFA500;  
  --accent-color: #17A2B8;  
  --text-color: #333;
  --heading-color: #000;
  --background-color: #F8F9FA;
  --light-color: #ffffff;
  --dark-color: #212529;
  --muted-color: #6c757d;

  --success-color: #198754;
  --warning-color: #FFC107;
  --danger-color: #DC3545;
  --neutral-color: #6C757D;

  --navbar-height:60px;
}

Material Design (Google's Theme)

:root {
  --primary-color: #6200EE;  
  --secondary-color: #03DAC6;  
  --accent-color: #FF0266;  
  --text-color: #212121;
  --heading-color: #000000;
  --background-color: #F5F5F5;
  --light-color: #FFFFFF;
  --dark-color: #121212;
  --muted-color: #757575;
  --success-color: #198754;
  --warning-color: #FFC107;
  --danger-color: #DC3545;
  --neutral-color: #6C757D;

  --navbar-height: 60px;
}

Bootstrap 5 Theme

:root {
  --primary-color: #0D6EFD;  
  --secondary-color: #6C757D;  
  --accent-color: #6610F2;  
  --text-color: #212529;
  --heading-color: #000;
  --background-color: #F8F9FA;
  --light-color: #FFFFFF;
  --dark-color: #343A40;
  --muted-color: #6C757D;
  --success-color: #198754;
  --warning-color: #FFC107;
  --danger-color: #DC3545;
  --neutral-color: #ADB5BD;

  --navbar-height: 60px;
}

Tailwind CSS Theme (Trendy & Utility-Based)

:root {
  --primary-color: #3B82F6;  
  --secondary-color: #10B981;  
  --accent-color: #F43F5E;  
  --text-color: #374151;
  --heading-color: #111827;
  --background-color: #F9FAFB;
  --light-color: #FFFFFF;
  --dark-color: #1F2937;
  --muted-color: #6B7280;
  --success-color: #16A34A;
  --warning-color: #FBBF24;
  --danger-color: #DC2626;
  --neutral-color: #9CA3AF;

  --navbar-height: 60px;
}

*/
:root {
  --primary-color: #4CAF50;  
  --secondary-color: #FFA500;  
  --accent-color: #17A2B8;  
  --text-color: #333;
  --heading-color: #000;
  --background-color: #F8F9FA;
  --light-color: #ffffff;
  --dark-color: #212529;
  --muted-color: #6c757d;

  --success-color: #198754;
  --warning-color: #FFC107;
  --danger-color: #DC3545;
  --neutral-color: #6C757D;

  --navbar-height:60px;
}
 


/* ===== Global Styles ===== */
body {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

/* ===== Page Container ===== */

html, body {
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevent page-level scroll */
}

.main-content {
  flex-grow: 1;
  overflow-y: auto; /* Allow scrolling only in content */  
  height: calc(100vh - var(--navbar-height)); /* Automatically adjusts */
  overflow-y: auto;
}


/* Theme color override over bootstrap Colors */

/* PRIMARY */
 /* General Button Styling */
.btn {
  font-weight: 500;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}

/* Primary Button */
.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--light-color) !important;
}
.btn-primary:hover {
  filter: brightness(90%); /* Darkens color by 10% */
}

/* Secondary Button */
.btn-secondary {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: var(--dark-color) !important;
}
.btn-secondary:hover {
  filter: brightness(90%);
}

/* Success Button */
.btn-success {
  background-color: var(--success-color) !important;
  border-color: var(--success-color) !important;
  color: var(--light-color) !important;
}
.btn-success:hover {
  filter: brightness(90%);
}

/* Warning Button */
.btn-warning {
  background-color: var(--warning-color) !important;
  border-color: var(--warning-color) !important;
  color: var(--dark-color) !important;
}
.btn-warning:hover {
  filter: brightness(85%); /* Slightly darker */
}

/* Danger Button */
.btn-danger {
  background-color: var(--danger-color) !important;
  border-color: var(--danger-color) !important;
  color: var(--light-color) !important;
}
.btn-danger:hover {
  filter: brightness(85%);
}

/* Info Button */
.btn-info {
  background-color: var(--accent-color) !important;
  border-color: var(--accent-color) !important;
  color: var(--light-color) !important;
}
.btn-info:hover {
  filter: brightness(90%);
}

/* Dark Button */
.btn-dark {
  background-color: var(--dark-color) !important;
  border-color: var(--dark-color) !important;
  color: var(--light-color) !important;
}
.btn-dark:hover {
  filter: brightness(110%); /* Lightens dark button */
}

/* Light Button */
.btn-light {
  background-color: var(--light-color) !important;
  border-color: var(--light-color) !important;
  color: var(--dark-color) !important;
}
.btn-light:hover {
  filter: brightness(95%);
}



/* Primary color */
.text-primary {
  color: var(--primary-color) !important;
}

/* Secondary color */
.text-secondary {
  color: var(--secondary-color) !important;
}

/* Accent color (Info) */
.text-info {
  color: var(--accent-color) !important;
}

/* Dark color */
.text-dark {
  color: var(--dark-color) !important;
}

/* Muted color */
.text-muted {
  color: var(--muted-color) !important;
}

/* Light color */
.text-light {
  color: var(--light-color) !important;
}

/* Default body text color */
.text-body {
  color: var(--text-color) !important;
}

/* Heading text color */
.text-heading {
  color: var(--heading-color) !important;
}

/* Success color (Theme-based) */
.text-success {
  color: var(--success-color, #198754) !important; /* Default Bootstrap green if not defined */
}

/* Warning color (Theme-based) */
.text-warning {
  color: var(--warning-color, #FFC107) !important; /* Default Bootstrap yellow if not defined */
}

/* Danger color (Theme-based) */
.text-danger {
  color: var(--danger-color, #DC3545) !important; /* Default Bootstrap red if not defined */
}

/* Custom Neutral (Soft Gray Alternative) */
.text-neutral {
  color: var(--neutral-color, #6C757D) !important;
}



/* Primary background */
.bg-primary {
  background-color: var(--primary-color) !important;
}

/* Secondary background */
.bg-secondary {
  background-color: var(--secondary-color) !important;
}

/* Accent background */
.bg-info {
  background-color: var(--accent-color) !important;
}

/* Success background */
.bg-success {
  background-color: var(--success-color, #198754) !important;
}

/* Warning background */
.bg-warning {
  background-color: var(--warning-color, #FFC107) !important;
}

/* Danger background */
.bg-danger {
  background-color: var(--danger-color, #DC3545) !important;
}

/* Dark background */
.bg-dark {
  background-color: var(--dark-color) !important;
}

/* Light background */
.bg-light {
  background-color: var(--light-color) !important;
}

/* Muted background */
.bg-muted {
  background-color: var(--muted-color) !important;
}

/* Neutral background (Custom) */
.bg-neutral {
  background-color: var(--neutral-color, #E0E0E0) !important;
}



/* ===== Profile Image Styling ===== */
.profile-img {
  width: max(40vmin,150px);
  height: auto;
  border-radius: 50%;
  border: 6px solid var(--light-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

/* ===== Typography Adjustments ===== */
h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
}

.main-title {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--heading-color);
  margin-bottom: 10px;
}

.sub-text {
  font-size: 1.2rem;
  color: var(--text-color);
}

.section-heading {
  text-align: left;  
  margin-bottom: 25px; /* Adds more gap between heading and content */
}

.section-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--accent-color);
  margin-bottom: 8px;
}

.section-text {
  font-size: 1.1rem;
  color: var(--text-color);
  line-height: 1.6;
}

.text-theme-primary {
  color: var(--primary-color) !important;
}

.text-theme-muted {
  color: var(--muted-color) !important;
}

.text-theme-dark {
  color: var(--dark-color) !important;
}

/* ===== Button Styling ===== */
.btn-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 25px;
  padding: 8px 18px;
  min-width: 130px;
  border: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  white-space: nowrap;
}

.shake {  
  animation: shake 1.5s infinite alternate ease-in-out;
}




/* Animated Icons inside Buttons */
.btn-icon {
  font-size: 1.2rem;
  
}
.btn:hover .btn-icon {
  animation: shake 0.5s infinite alternate ease-in-out;
}


/* =============  Animation keyframes ================= */
@keyframes shake {
  0% { transform: translate(0, 0); }
  25% { transform: translate(-1px, 1px) rotate(-5deg); }
  50% { transform: translate(1px, -1px) rotate(5deg); }
  75% { transform: translate(-1px, -1px) rotate(-5deg); }
  100% { transform: translate(1px, 1px) rotate(5deg); }
}





@keyframes blink {
  50% {
      opacity: 0;
  }
}

@keyframes grayscaleLoop {
  0% { filter: grayscale(0%); }
  20% { filter: grayscale(50%); }
  50% { filter: grayscale(100%); }
  70% { filter: grayscale(50%); }
  100% { filter: grayscale(0%); }
}


/* ===== Navbar Styling ===== */
.navbar {
  background-color: var(--light-color);
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: var(--navbar-height);
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.4rem;
}

.navbar .nav-link {
  color: var(--text-color);
  font-weight: 500; 
  transition: color 0.3s ease-in-out;
}

/* Active Page Highlight */
.navbar .nav-link.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  font-weight: bold;

}
/* Navbar Hover Effect */
.navbar .nav-link:hover {
  color: var(--accent-color);
}

/*=======================Home ==========*/

.typewriter-text-enclosed {
  font-size: 24px;
  font-weight: 500;
  color:var(--primary-color);
  font-family: "Courier New", Courier, monospace; 
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  min-width: 20px;
}

.typewriter-text-enclosed::before {
  content: "{";
  display: inline-block;
}

.typewriter-text-enclosed::after {
  content: "}";
  display: inline-block;
}

.typewriter-text {
  font-size: 24px;
  font-weight: 500;
  color: #007bff;
  font-family: "Courier New", Courier, monospace; 
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  min-width: 20px;
}



.caret {
  display: inline-block;
  animation: blink 0.7s steps(2, start) infinite;
}






/* ======== Image Animation =========*/
 

.scale-changing-image-container img {
 
 
  filter: grayscale(0%);
  transition: filter 2s ease-in-out;
  animation: grayscaleLoop 8s infinite alternate;
}



/* ===== Portfolio Card Styling ===== */
.card {
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  background-color: var(--light-color);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: scale(1.05);
}

.card-title {
  font-size: 1.4rem;
  color: var(--heading-color);
}

.card-text {
  font-size: 1rem;
  color: var(--text-color);
}

/* Project Technology Badges */
.badge {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.badge-theme-accent {
  background-color: var(--accent-color);
  color: var(--dark-color);
}

/* ===== About Page Styling ===== */
.container {
  max-width: 800px;
}

h4 {
  font-size: 1.3rem;
}

.text-muted {
  font-style: italic;
}



 

.text-purple {
  color: #6f42c1; /* Cool purple for Education section */
}



.company-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  display: block;
}

.icon-shake {
  animation: shake 1.5s infinite ease-in-out;
}




/*========= Articles Styleing =============*/

.article-image {
  max-width: 100%;  /* Ensures it doesn't overflow */
  height: auto;     /* Maintains aspect ratio */
  display: block;   /* Prevents unwanted spacing */
  margin: 0 auto;   /* Centers the image */
}


/* ===== Footer Styling ===== */
.footer {
  text-align: center;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  border-top: 2px solid var(--primary-color);


}



/* ===== Responsive Design ===== */
@media (max-width: 768px) {
 
  
  .main-title {
      font-size: 1.8rem;
  }

  .section-title {
      font-size: 1.5rem;
  }

  .btn-custom {
      font-size: 0.9rem;
      padding: 6px 6px;   
      min-width: 80px;
  }
}

@media (max-width: 480px) {


  .main-title {
      font-size: 1.6rem;
  }

  .section-title {
      font-size: 1.3rem;
  }

    .btn-custom {
      font-size: 0.9rem;
      padding: 6px 2px;   
      min-width: 50px;
  }
}
